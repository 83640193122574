<template lang="html">
<div class="VideochatRestyling">
  <button v-show="!initVideoChat" type="button" name="button" v-on:click="start()">EMPESAR</button>
  <Videochat v-if="initVideoChat" v-on:hide-header="$emit('hide-header',$event)"/>
</div>
</template>
<!--
Funcionalidades Focous:
  n participantes
  Permisos de acceso ->administrador
  Compartir pantalla  por todos los participantes con control del moderador
  Mutear a los participantes -> administrador
  Expulsar a los participantes
  Chat de usuarios
  Chat de moderador

  Funcionalidades Entrevistas personales:
    1 participantes ( hacer la pantalla del moderador algo mas grande)
    Compartir pantalla  por todos los participantes con control del moderador
    Mutear a los participantes -> administrador
    Chat de usuarios
    Chat de moderador
 -->
<script>
import Videochat from '@/components/Videochat/Videochat.vue'
export default {
  name: 'VideochatRestyling',
  components:{
    Videochat
  },
  data(){
    return{
      initVideoChat:false,
    }
  },
  emits: ['hide-header'],
  methods:{
    start(){
      this.initVideoChat = true
    },
  }
}
</script>
<style scoped>
  .VideochatRestyling{
    width: 100%;
  }
</style>
