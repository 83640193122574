import ApiService from '../services/api.service'
import { VueCookieNext } from 'vue-cookie-next'

export const FETCH_FOCUS_DATA_MODERATOR = "FETCH_FOCUS_DATA_MODERATOR"
export const FETCH_FOCUS_DATA_OBS = "FETCH_FOCUS_DATA_OBS"
export const FETCH_TESTER_JOIN_SLOT = "FETCH_TESTER_JOIN_SLOT"
export const GRANT_TESTER_ACCESS = "GRANT_TESTER_ACCESS"
export const FETCH_WAT_FOCUS_DATA = "FETCH_WAT_FOCUS_DATA"
export const FETCH_ONLINE_USERS = "FETCH_ONLINE_USERS"
export const FETCH_WAT_FOCUS_USERS = "FETCH_WAT_FOCUS_USERS"
export const SAVE_WAT_FOCUS_PREVIOUS_CONNECTIVITY = "SAVE_WAT_FOCUS_PREVIOUS_CONNECTIVITY"
export const SAVE_OBSERVATOR_NAME = "SAVE_OBSERVATOR_NAME"
export const FETCH_FOCUS_TESTER = "FETCH_FOCUS_TESTER"
export const SET_ONLINE_USERS = "SET_ONLINE_USERS"
export const ADD_FOCUS_OBSERVER_CHAT_MESSAGE = "ADD_FOCUS_OBSERVER_CHAT_MESSAGE"
export const ADD_FOCUS_CHAT_MESSAGE = "ADD_FOCUS_CHAT_MESSAGE"
export const WAT_FOCUS_PUT_MESSAGE = "WAT_FOCUS_PUT_MESSAGE"
export const WAT_FOCUS_PUT_MESSAGE_OBSERVATOR = "WAT_FOCUS_PUT_MESSAGE_OBSERVATOR"
export const SAVE_FOCUS_CHAT_MESSAGES = "SAVE_FOCUS_CHAT_MESSAGES"
export const SAVE_FOCUS_OBSERVER_CHAT_MESSAGES = "SAVE_FOCUS_OBSERVER_CHAT_MESSAGES"
export const FETCH_WAT_FOCUS_CHAT_MESSAGES = "FETCH_WAT_FOCUS_CHAT_MESSAGES"
export const FETCH_WAT_FOCUS_CHAT_MESSAGES_OBS = "FETCH_WAT_FOCUS_CHAT_MESSAGES_OBS"
export const FETCH_WAT_FOCUS_FILE = "FETCH_WAT_FOCUS_FILE"
export const PUT_WAT_FOCUS_COMMENT = "PUT_WAT_FOCUS_COMMENT"
export const WAT_FOCUS_DELETE_CHAT_MESSAGE = "WAT_FOCUS_DELETE_CHAT_MESSAGE"
export const WAT_FOCUS_PUT_MESSAGE_PUBLIC = "WAT_FOCUS_PUT_MESSAGE_PUBLIC"

const state = {
  onlineUsers:{},
  focusChatMessagesObs:[],
  focusChatMessages:[]
}

const getters = {
  onlineUsers(state: any){
    return state.onlineUsers
  },
  focusChatMessages(state: any){
    return state.focusChatMessages
  },
  focusChatMessagesObs(state: any){
    return state.focusChatMessagesObs
  }
}

const actions = {
  async FETCH_FOCUS_DATA_MODERATOR(context:any, payload:any) {
    return ApiService
      .get(`/client/watfocus/${payload.id}/slot/${payload.slotHash}/videochatdata`, null)
      .then(({data}: any) => {
        return data
      })
      .catch(({response}: any)  => {
        return Promise.reject(response)
      })
  },
  async FETCH_FOCUS_DATA_OBS(context:any, payload:any) {
    return ApiService
      .get(`/client/watfocus/${payload.id}/slot/${payload.slotHash}/videochatdataobs`, null)
      .then(({data}: any) => {
        return data
      })
      .catch(({response}: any)  => {
        return Promise.reject(response)
      })
  },
  async GRANT_TESTER_ACCESS(context:any, payload:any) {
    return ApiService
      .post(`/client/watfocus/${payload.id}/slot/${payload.slotHash}/tester/${payload.testerId}`, payload.grantAccess)
      .then(({data}: any) => {
        return data
      })
      .catch(({response}: any)  => {
        return Promise.reject(response)
      })
  },
  async FETCH_TESTER_JOIN_SLOT(context:any, payload:any) {
    let headers = {}
    if (payload.isPublic) {
      headers = {headers:{'tesVisToken':payload.visitorToken}}
    }

    //VueCookieNext.setCookie('testerVisitorToken', payload.visitorToken)

    const endPoint = payload.isPublic ? `/tester/watfocus/${payload.id}/slot/${payload.slotHash}/public` : `/tester/watfocus/${payload.id}/slot/${payload.slotHash}`
    return ApiService
      .get(endPoint, null, headers)
      .then(({data}: any) => {
        return data
      })
      .catch(({response}: any)  => {
        return Promise.reject(response)
      })
  },
  async FETCH_WAT_FOCUS_DATA(context:any, payload:any) {
    return ApiService
      .get(`/watFocus/${payload.id}/slot/${payload.slotHash}`, null)
      .then(({data}: any) => {
        return data
      })
      .catch(({response}: any)  => {
        return Promise.reject(response)
      })
  },
  async FETCH_ONLINE_USERS(context:any, payload:any) {
    return ApiService
      .get(`/client/watfocus/${payload.id}/slot/${payload.slotHash}/onlineusers`, null)
      .then(({data}: any) => {
        context.commit(SET_ONLINE_USERS,data.onlineUsers)
        return data
      })
      .catch(({response}: any)  => {
        return Promise.reject(response)
      })
  },
  async FETCH_WAT_FOCUS_USERS(context:any, payload:any) {
    return ApiService
      .get(`/client/watfocus/${payload.id}/slot/${payload.slotHash}/users`, null)
      .then(({data}: any) => {
        return data
      })
      .catch(({response}: any)  => {
        return Promise.reject(response)
      })
  },
  async SAVE_WAT_FOCUS_PREVIOUS_CONNECTIVITY(context:any, payload:any) {
    let headers = {}
    if (payload.isPublic) {
      headers = {headers:{'tesVisToken':payload.visitorToken}}
    }
    return ApiService
      .post(`/tester/watfocus/${payload.id}/slot/${payload.slotHash}/connectivity`, payload.data, headers)
      .then(({data}: any) => {
        return data
      })
      .catch(({response}: any)  => {
        return Promise.reject(response)
      })
  },
  async SAVE_OBSERVATOR_NAME(context:any, payload:any) {
    return ApiService
      .post(`/client/watfocus/${payload.id}/slot/${payload.slotHash}/observator`, payload.data)
      .then(({data}: any) => {
        return data
      })
      .catch(({response}: any)  => {
        return Promise.reject(response)
      })
  },
  async FETCH_FOCUS_TESTER(context:any, payload:any) {
    VueCookieNext.setCookie('testerVisitorToken', payload.visitorToken)
    let headers = {}
    if (payload.isPublic) {
      headers = {headers:{'tesVisToken':payload.visitorToken}}
    }
    return ApiService
      .get(`/tester/watfocus/${payload.id}/slot/${payload.slotHash}/tester`, null, headers)
      .then(({data}: any) => {
        return data
      })
      .catch(({response}: any)  => {
        return Promise.reject(response)
      })
  },
  async WAT_FOCUS_PUT_MESSAGE_OBSERVATOR(context:any, payload:any) {
    return ApiService
      .post(`/client/watfocus/${payload.id}/slot/${payload.slotHash}/chat/${payload.isModerator ? 'toObserver' : 'fromObserver'}`, payload.data)
      .then(({data}: any) => {
        return data
      })
      .catch(({response}: any)  => {
        return Promise.reject(response)
      })
  },
  async WAT_FOCUS_PUT_MESSAGE (context: any, payload: any) {
    return ApiService
      .post(`${payload.isModerator ? '/client' : '/tester'}/watfocus/${payload.id}/slot/${payload.slotHash}/chat`, payload.data)
      .then(({data}) => {
        return data
      })
      .catch(({response}) => response);
  },
  async WAT_FOCUS_PUT_MESSAGE_PUBLIC (context: any, payload: any) {
    let headers = {headers:{'tesVisToken':VueCookieNext.getCookie("testerVisitorToken")}}
    return ApiService
      .post(`/tester/watfocus/${payload.id}/slot/${payload.slotHash}/chatPublic`, payload.data, headers)
      .then(({data}) => {
        return data
      })
      .catch(({response}) => response);
  },
  async FETCH_WAT_FOCUS_CHAT_MESSAGES (context: any, payload: any) {
    return ApiService
      .get(`${payload.isModerator ? '/client' : '/tester'}/watfocus/${payload.id}/slot/${payload.slotHash}/chat`, null)
      .then(({data}) => {
        context.commit(SAVE_FOCUS_CHAT_MESSAGES, data)
        return data
      })
      .catch(({response}) => response);
  },
  async FETCH_WAT_FOCUS_CHAT_MESSAGES_OBS (context: any, payload: any) {
    return ApiService
      .get(`/client/watfocus/${payload.id}/slot/${payload.slotHash}/chatObs`, null)
      .then(({data}) => {
        context.commit(SAVE_FOCUS_OBSERVER_CHAT_MESSAGES, data)
        return data
      })
      .catch(({response}) => response);
  },
  async FETCH_WAT_FOCUS_FILE (context: any, payload: any) {
    return ApiService
      .get('/experienceLabs/client/archive/'+payload.archiveId, null)
  },
  async PUT_WAT_FOCUS_COMMENT (context: any, payload: any) {
    return ApiService
    .post('/client/watfocus/' + payload.watFocusId + '/slot/' + payload.slotHash + '/videoComment', payload.data)
    .then(({data}) => {
        return data
      })
      .catch(({response}) => response);
  },
  async WAT_FOCUS_DELETE_CHAT_MESSAGE (context: any, payload: any){
    return ApiService
    .delete('/client/watfocus/chat/messages',{ messageIds: payload.messageIds })
  },
}

const mutations = {
  
  SET_ONLINE_USERS(state: any,data: any){
    state.onlineUsers = data;
  },
  SAVE_FOCUS_OBSERVER_CHAT_MESSAGES(state: any, data: any){
    state.focusChatMessagesObs = data.messages;
  },
  SAVE_FOCUS_CHAT_MESSAGES(state: any, data: any){
    state.focusChatMessages = data.messages;
  },
  ADD_FOCUS_OBSERVER_CHAT_MESSAGE(state: any, data: any){
    state.focusChatMessagesObs.push(data)
  },
  ADD_FOCUS_CHAT_MESSAGE(state: any, data: any){
    state.focusChatMessages.push(data)
  }
}

export default{
  actions,
  state,
  getters,
  mutations
}
