<template>
  <div class="SwitchInput" >
    <div class="SwitchInput--input" v-show="!disabled" v-on:click="update" :class="{'active':value}">
      <div class="">

      </div>
    </div>
    <p v-html="text"></p>
  </div>
</template>
<script>
  export default {
    name: 'SwitchInput',
    props:{
      text: String,
      modelValue: Boolean,
      disabled:{
        type: Boolean,
        default: false
      },
    },
    emits:['update:modelValue', 'input'],
    watch:{
      value(newValue, oldValue){
        this.on = newValue
      }
    },
    computed: {
      value: {
        get() {
          return this.modelValue
        },
        set(newValue) {
          this.$emit('update:modelValue', newValue)
          this.$emit('input', newValue)
        }
      }
    },
    methods: {
      update() {
        if (this.disabled) {
           return 
        }
        this.value = !this.value
      }
    }
  }
</script>
<style scoped lang="scss">
.SwitchInput{
  display: flex;
  align-items: center;
  &--Small{
    .SwitchInput--input{
      height: 22px;
      div{
        height: 15px;
        width: 15px;
      }
    }
  }
  &--input{
    background: #E0E0E0;
    width: 58px;
    height: 34px;
    border-radius: 20px;
    display: flex;
    align-items: center;
    cursor: pointer;
    &.active{
      div{
        margin-left: calc(100% - 25px);
        opacity:1;
      }
    }
    div{
      background: linear-gradient(90deg, #FF1564 0%, #FD7328 100%);
      opacity:0.5;
      width: 20px;
      height: 20px;
      border-radius: 20px;
      margin:0 5px;
      transition: 0.5s;
    }
  }
  p{
    margin-left: 15px;
    font-family: Roboto Condensed;
    font-style: normal;
    font-weight: normal;
    font-size: 19px;
    letter-spacing: 0.01em;
    color: #3E3E3E;
  }
}
</style>
