<template lang="html">
  <div class="Videochat" :class="{'screen-view':isSharingScreenExternal, 'default':!isSharingScreenExternal}">
    <div class="Videochat--View">
      <ActionsBar
        v-on:full-size="fullSize()"
        v-on:change-chat="changeTab($event)"
        v-on:open-permissions="changeTab($event)"
        v-on:exit="exit()"
        v-on:mute="mute()"
        v-on:share-screen="shareScreen()"
        v-on:share-links="$emit('share-links')"
        v-on:applyBlurEffect="applyBlurEffect()"
        :isMuted="isMuted || isMutedByModerator"
        :isSharingScreen="isSharingScreen"
        :showObserverChat="isClient"
        :showPermissions="isModerator"
        :showScreenShare="hasPermissionsToShare()"
        :showMuteOption="!isObservator"
        :activeTab="selectedTab"
        :showFullSize="isClient || (!communityCurrentActivity.publicActivity && isUser)"
        :showUrls="isModerator"
        :showComments="isModerator"
        :badgePermissions="badgePermissions"
        :badgeChatObserver="badgeChatObserver"
        :badgeChatActivity="badgeChatActivity"
        :isBlurEffect="isBlurEffect"/>
      <div class="Videochat--Content" :class="{'tabs-hidden':selectedTab == -1}">
        <div class="Videochat--Video">
          <div id="Videochat--Screen" class="Videochat--Screen" v-show="isSharingScreenExternal">
          </div>
          <div class="Videochat--Cameras">
            <div id="Videochat--User" class="Videochat--User">
            </div>
            <div id="Videochat--Streams" class="Videochat--Streams">
              <div id="Videochat--StreamsFakeContainer" style="display:none">

              </div>
            </div>
          </div>
        </div>
        <div class="Videochat--Tabs">
          <Chat :name="userProfile.name" :isModerator="isModerator" :isUser="isUser" :isClient="isClient" ref="chatActivity" v-if="selectedTab == 0" :members="activityMembers" :title="$t('videochat_chat_activity')" :messages="activityChatMessages" :disabled="isObservator" :disableActions="isObservator" v-on:send-message="$emit('activity-message-send',$event)" />
          <Chat :name="userProfile.name" :isModerator="isModerator" :isUser="isUser" :isClient="isClient"  ref="chatObserver" class="Videochat--ObserverChat" v-if="selectedTab == 1" :title="$t('videochat_observator_chat')" :messages="communityChatMessagesObs" v-on:send-message="$emit('observer-message-send',$event)" secretIcon/>
          <AccessPermissions v-if="selectedTab == 2" :accessUsers="accessUsers" v-on:grant-access="$emit('grant-access',$event)" :isPublicActivity="communityCurrentActivity.publicActivity"/>
          <VideochatComments v-if="selectedTab == 3" :currentTimeFromStart="currentTimeFromStart" v-on:save-comment="sendVideoFileComment($event)"/>
          <div v-show="selectedTab != -1" class="Videochat--CloseTabs" v-on:click="changeTab(-1)">
            <span></span>
            <span></span>
          </div>
        </div>
      </div>
    </div>
    <AccessPermissionsModal v-if="showPermissionsModal" :userName="permissionUserName" :testerName="testerName" v-on:grant-access="grantAccessFromModal($event)" v-on:close="showPermissionsModal = false"/>
  </div>
</template>
<!--
Funcionalidades Focous:
  n participantes OK
  Permisos de acceso ->administrador
  Compartir pantalla  por todos los participantes con control del moderador
  Mutear a los participantes -> administrador
  Expulsar a los participantes
  Chat de usuarios
  Chat de moderador

  Funcionalidades Entrevistas personales:
    1 participantes ( hacer la pantalla del moderador algo mas grande)
    Compartir pantalla  por todos los participantes con control del moderador
    Mutear a los participantes -> administrador
    Chat de usuarios
    Chat de moderador
 -->
<script>
import Swal from 'sweetalert2'
import moment from 'moment-timezone';
import { mapGetters, mapMutations  } from 'vuex';
import CommunityButton from '@/components/CommunityButton.vue'
import VideochatComments from '~/components/WatFocus/VideochatComments.vue'
import AccessPermissionsModal from '~/components/WatFocus/AccessPermissionsModal.vue'
import Chat from '~/components/WatFocus/Chat.vue'
import ActionsBar from '~/components/WatFocus/ActionsBar.vue'
import AccessPermissions from '~/components/WatFocus/AccessPermissions.vue'
import store from '@/store'
import { initSession, connectSession, getSession, getPublisher, createSignal, muteCurrentUser, canShareScreen, applyBlurEffect } from '~/utils/videochat-restyling.js'
import {
  COMMUNITY_FETCH_VIDEO_CHAT,
  COMMUNITY_VIDEOCHAT_FILE,
  COMMUNITY_VIDEOCHAT_FILE_COMMENT
 } from '@/store/community/chat.module';
export default {
  name: 'Videochat',
  components:{
    CommunityButton,
    Chat,
    ActionsBar,
    AccessPermissions,
    VideochatComments,
    AccessPermissionsModal
  },
  props:{
    activityChatMessages:Array,
    accessUsers:Object,
    badgePermissions:{
        type: Number,
        default: 0
    },
    badgeChatObserver: Number,
    activityMembers:Object
  },
  computed:{
    ...mapGetters([
      'isClient',
      'isUser',
      'isObservator',
      'isModerator',
      'userProfile',
      'communityCurrentActivityId',
      'communityCurrentActivity',
      'communityChatMessagesObs'
    ])
  },
  data(){
    return{
      selectedTab:0,
      isFullSize:false,
      isMuted:false,
      isMutedByModerator:false,
      isSharingScreen: false,
      isSharingScreenExternal: false,
      screenPublisher: null,
      badgeChatActivity:0,
      recordingStartedDateInMs: 0,
      currentTimeFromStart: 0,
      currentSecondsFromStart: 0,
      showPermissionsModal: false,
      deniedUsers:[],
      permissionUserName:'',
      testerName:'',
      isBlurEffect: false
    }
  },
  emits: ['hide-header', 'grant-access'],
  watch: {
    communityCurrentActivityId: async function(val) {
      await this.destroySession()
    },
    badgeChatObserver: async function(val){
      if(this.selectedTab == 1) this.$emit('observer-chat-opened')
    },
    badgePermissions: {
        async handler(val) {
            if(this.showPermissionsModal) return
            if(this.isObservator) return
            for(let index in this.accessUsers){
                const user = this.accessUsers[index]
                if(!user.accessGranted && user.online && !this.deniedUsers.includes(index)) {
                    this.permissionUserName = index
                    this.testerName = user.testerName
                    this.showPermissionsModal = true
                    return
                }
            }
      },
      immediate: true
    }
  },
  methods:{
    exit(){
      this.destroySession()
      this.$emit('exit')
    },
    destroySession(){
      if(getSession() != null){
        getSession().off();
        getSession().disconnect();
      }
      if(getPublisher() != null) getPublisher().destroy()
      if(this.screenPublisher != null && this.isSharingScreen) this.screenPublisher.destroy()
    },
    changeTab(tabIndex){
      if(this.selectedTab == tabIndex) return this.selectedTab = -1
      if(tabIndex == 1) this.$emit('observer-chat-opened')
      if(tabIndex == 0) this.badgeChatActivity = 0
      if(tabIndex == 3) this.openCommentsModal()
      this.selectedTab = tabIndex
    },
    fullSize(){
      this.isFullSize = !this.isFullSize
      this.$emit('hide-header',this.isFullSize)
    },
    async getVideoChatInfo(){
      await store.dispatch(COMMUNITY_FETCH_VIDEO_CHAT,{isObservator:this.isObservator})
      .then((data) => {
        if(data.sessionId != undefined && data.sessionId != null){
          let participantName = this.userProfile.name
          if (this.userProfile.nickname != undefined && !this.userProfile.nickname.includes("-"+this.userProfile.id)) {
            participantName = this.userProfile.nickname
          }
          initSession(data.sessionId)
          connectSession(data.token, this.isObservator, this.isClient, participantName)
          this.detectNewStream(data)
          this.detectStreamDestroyed(data)
          this.detectSignals(data)
          if(this.isModerator) this.detectArchivingStart(data)
          this.setStreamsGridSize()
        }
      })
    },
    detectArchivingStart(data){
      let vm = this
      getSession().on("archiveStarted", function(event) {
        store.dispatch(COMMUNITY_VIDEOCHAT_FILE,{archiveId:event.id})
        .then(({data}) => {
          vm.recordingStartedDateInMs = data.createdAt
        })
      });
    },
    detectStreamDestroyed(data){
      let vm = this
      getSession().on("streamDestroyed", function(event) {
        if (vm.screenPublisher != null && event.stream === vm.screenPublisher.stream) {
          vm.isSharingScreenExternal = false
        }
      });
    },
    detectNewStream(data){
      let vm = this
      getSession().on('streamCreated', function(event) {
        const isScreenStream = event.stream.videoType === 'screen'
        const DOMElement = isScreenStream ? 'Videochat--Screen' : 'Videochat--StreamsFakeContainer'
        const isModerator = event.stream.connection.data.split("=")[1] == "Moderador" ? true : false
        const insertMode = isScreenStream ? 'append' : isModerator ? 'before' : 'after'

        var subscriberProperties = {
          insertMode: insertMode,
          width: '100%',
          height: '100%',
          style: {buttonDisplayMode: 'off',nameDisplayMode: 'on', audioBlockedDisplayMode:'on'}
        };
        let subscriber = getSession().subscribe(event.stream, DOMElement, subscriberProperties, function (error) {
          if(event.stream.videoType == 'screen') vm.isSharingScreenExternal = true
          if(vm.isModerator) vm.generateUserActions(subscriber, event.stream.videoType == 'screen')
        });
      });
    },
    detectSignals(data){
      const vm = this
      getSession().on("signal", function(event) {
        const eventType = event.data.type
        if(eventType == 'MUTE') vm.executeSignalMute(event, true)
        else if(eventType == 'UNMUTE') vm.executeSignalMute(event, false)
        else if(eventType == 'REMOVE_USER') vm.executeSignalRemoveUser(event)
        else if(eventType == 'CLOSE_USER_SCREEN') vm.executeSignalCloseScreen(event)
      });
    },
    executeSignalMute(event, mute){
      if(mute) $('#'+event.data.userId).append("<div class='UserMutedIcon'><i class='fas fa-volume-mute'></i></div>")
      else $('#'+event.data.userId + ' .UserMutedIcon').remove()
      if(getPublisher().streamId == event.data.id && !this.isModerator){
        this.isMutedByModerator = mute
        muteCurrentUser(!mute)
      }
    },
    executeSignalRemoveUser(event){
      if(getPublisher().streamId == event.data.id && !this.isModerator){
        this.exit()
        this.$router.push({ name: 'videochat-exit',params:{ reason:event.data.message } })
      }
    },
    executeSignalCloseScreen(event){
      if(!this.isModerator) this.stopScreenSharing()
    },
    generateUserActions(subscriber, isScreenStream){
      $( "#"+subscriber.id).append("<div class='UserMenuActions'><i class='fas fa-ellipsis-v'></i></div>")
      $( "#"+subscriber.id).append("<div class='UserMenuActions--List'><p class='UserMenuActions--Exit'><i class='fas fa-user-times'></i>"+(isScreenStream ? "Cerrar pantalla" : "Expulsar") +"</p><p class='UserMenuActions--Mute'><i class='fas fa-microphone-alt UserMenuActions--notMute'></i><i class='fas fa-microphone-alt-slash UserMenuActions--muted'></i>Silenciar</p></div>")
      this.generateClickEventHandlers(subscriber, isScreenStream)
    },
    generateClickEventHandlers(subscriber, isScreenStream){
      const vm = this

      $(window).click(function() {
        $( "#"+subscriber.id+" .UserMenuActions--List").hide()
      });

      $( "#"+subscriber.id+" .UserMenuActions" ).on( "click", function(event) {
        event.stopPropagation();
        const element = $( "#"+subscriber.id+" .UserMenuActions--List")
        if ( element.is(":visible") ) element.hide()
        else element.show().css('display', 'flex')
      });

      $( "#"+subscriber.id+" .UserMenuActions--Mute" ).on( "click", function(event) {
        event.stopPropagation();
        const element = $( "#"+subscriber.id+" .UserMenuActions--Mute")
        if (element.hasClass("userMute")){
          createSignal(subscriber.id, subscriber.streamId,"external","UNMUTE",null)
          element.removeClass('userMute')
        }
        else {
          createSignal(subscriber.id, subscriber.streamId,"external","MUTE",null)
          element.addClass('userMute')
        }
      });

      $( "#"+subscriber.id+" .UserMenuActions--Exit" ).on( "click", function(event) {
        event.stopPropagation();
        if(isScreenStream){
          Swal.fire({
            text: vm.$t('user_will_stop_sharing_screen'),
            icon: 'warning',
            cancelButtonText: vm.$t('action_cancel'),
            confirmButtonText: vm.$t('action_continue'),
            showCancelButton: true,
            customClass:{
              popup:'swal-customWarning'
            }
          }).then((result) => {
            if (result.isConfirmed) {
              createSignal(subscriber.id, subscriber.streamId,"external","CLOSE_USER_SCREEN")
            }
          })
        }
        else{
          Swal.fire({
          title: vm.$t('msg_videochat_out_message'),
          text: vm.$t('msg_videochat_out_reason'),
          input: 'text',
          showCancelButton: true,
          cancelButtonText: vm.$t('action_cancel'),
          confirmButtonText: vm.$t('action_out')
          }).then((result) => {
            if (result.isConfirmed && result.value != null) {
              vm.$emit('user-removed',subscriber.stream.name)
              vm.deniedUsers.push(subscriber.stream.name)
              createSignal(subscriber.id, subscriber.streamId,"external","REMOVE_USER",result.value)
            }
          })
        }
      });

    },
    hasPermissionsToShare(){
      return !this.isObservator && (this.isUser && !this.isSharingScreenExternal && this.communityCurrentActivity.videochatUserShareScreen) || this.isModerator
    },
    showScreenPermissionError(){
      let vm = this
      Swal.fire({
        text: vm.$t('check_your_permissions'),
        icon:"error",
        customClass:{popup:"swal-customError"}
      })
    },
    publishScreenShare(){
      let vm = this

      let participantName = this.userProfile.name
      if (this.userProfile.nickname != undefined && !this.userProfile.nickname.includes("-"+this.userProfile.id)) {
        participantName = this.userProfile.nickname
      }

      const publisherOptions = {
        name: this.isClient ? "Moderador" : participantName,
        insertMode: 'append',
        width: '100%',
        height: '100%',
        videoSource : 'screen',
        nameDisplayMode: "on"
      }

      this.screenPublisher = OT.initPublisher('Videochat--Screen', publisherOptions, function(error) {
        if(error){
          vm.isSharingScreen = false
          if(error.originalMessage != 'Permission denied') vm.showScreenPermissionError()
        }
        else {
          getSession().publish(vm.screenPublisher, function(error) {
            if (error) {
              vm.isSharingScreen = false
              vm.showScreenPermissionError()
              if (error.name != null && error.name == 'OT_UNEXPECTED_SERVER_RESPONSE') {
                console.log("SERVER ERROR: OpenTok unexpected error")
              }
            }
            vm.isSharingScreen = true
          });
        }
      });

      this.screenPublisher.on({
        streamDestroyed: function (event) {
            vm.isSharingScreen = false
        }
      });
    },
    shareScreen(){
      if(!canShareScreen()){
        let vm = this
        Swal.fire(vm.$t('browser_can_not_share_screen'))
        return
      }

      if(this.isSharingScreen){
        this.stopScreenSharing()
        return
      }

      this.publishScreenShare()
    },
    stopScreenSharing(){
      this.isSharingScreen = false
      this.screenPublisher.destroy()
    },
    setStreamsGridSize(){
      var usersNum = this.communityCurrentActivity.publicActivity ? this.communityCurrentActivity.videochatPublicParticipantsNum : this.communityCurrentActivity.membersNum
      if(this.isObservator) usersNum++
      if(usersNum == 2 ) $('.Videochat--Streams').addClass("Stream--min")
      if(usersNum >= 3 && usersNum <=4) $('.Videochat--Streams').addClass("Stream--few")
      if(usersNum >= 5 && usersNum<=6) $('.Videochat--Streams').addClass("Stream--medium")
      if(usersNum >= 7 && usersNum<=8) $('.Videochat--Streams').addClass("Stream--almostCompleted")
      if(usersNum >= 9 && usersNum<=12) $('.Videochat--Streams').addClass("Stream--full")
      if(usersNum >= 13) $('.Videochat--Streams').addClass("Stream--infinite")
    },
    mute(){
      if(this.isMutedByModerator) return
      this.isMuted = !this.isMuted
      muteCurrentUser(!this.isMuted)
    },
    newMessageChat(message){
      if((this.isUser && message.isModerator) || (this.isClient && !message.isModerator)) this.badgeChatActivity++
    },
    closeTabsOnmobileView(){
      if(window.innerWidth <= 768) this.selectedTab = -1
    },
    async openCommentsModal(){
      const currentDate = new Date();
      const start = new Date(this.recordingStartedDateInMs);
      const hours = parseInt(Math.abs(start - currentDate) / (1000 * 60 * 60) % 24);
      const minutes = parseInt(Math.abs(start.getTime() - currentDate.getTime()) / (1000 * 60) % 60);
      const seconds = parseInt(Math.abs(start.getTime() - currentDate.getTime()) / (1000) % 60);
      this.currentTimeFromStart = ( hours < 10 ? '0'+hours : hours ) + ':' + ( minutes < 10 ? '0'+minutes : minutes ) + ':' + ( seconds < 10 ? '0'+seconds : seconds )
      this.currentSecondsFromStart = parseInt(Math.abs((currentDate - start) / 1000))
    },
    async sendVideoFileComment(comment){
      const videoCommentData = {
        secondsFromStart: this.currentSecondsFromStart,
        comment: comment
      }

      await store.dispatch(COMMUNITY_VIDEOCHAT_FILE_COMMENT,{activityId:this.communityCurrentActivityId, data : videoCommentData})
      .then((data) => {
        let vm = this
        Swal.fire('',vm.$t('annotation_saved'),'success')
        this.changeTab(-1)
      })
    },
    grantAccessFromModal(event){
      let user = this.accessUsers[event.userName]
      user.accessGranted = event.accessGranted;
      if( event.accessGranted ) this.$emit('grant-access', user)
      else this.deniedUsers.push(user.nickName)
      this.showPermissionsModal = false
    },
    applyBlurEffect(){
      this.isBlurEffect = !this.isBlurEffect
      applyBlurEffect(this.isBlurEffect)
    }
  },
  async mounted(){
    this.closeTabsOnmobileView()
    if(this.communityCurrentActivity.publicActivity && (this.isUser || this.isObservator)) this.fullSize()
    this.getVideoChatInfo()
  }
}
</script>
<style lang="scss">
  .Videochat{
    &.screen-view{
      .Videochat--Video{
        display: grid;
        grid-template-columns: auto 150px;
      }
      .Videochat--Cameras{
        flex-direction: column;
        overflow-y: auto;
      }
      .OT_publisher{
        width: 100%!important;
        height: 130px!important;
      }
      .Videochat--Streams{
        grid-template-rows: repeat(auto-fill, minmax(130px, 1fr));
        grid-template-columns: auto;
      }
      .OT_subscriber{
        border: 1px solid lightgray;
        margin-bottom: 10px;
        height: 50%;
      }
      .Videochat--Streams{
        height: 25%;
        width: 100%;
      }
    }
    &.default{
      .Videochat--Streams{
        height: 100%;
        width: 100%;
        display: grid;
        grid-gap: 5px;
      }
    }
    .OT_publisher{
      border: 1px solid var(--primary-color);
    }
    .OT_subscriber{
      border: 1px solid lightgray;
    }
    .ChatVideo{
      font-family: Roboto Condensed;
      height: 100%;
    }
    .ChatVideo--Messages{
      height: calc(100vh - 315px);
    }
    .UserMutedIcon{
      position: absolute;
      bottom: 5px;
      right: 5px;
      i{
        font-family:"Font Awesome 5 Free";
        font-size: 20px;
      }
    }
    .UserMenuActions{
      position:absolute;
      top:7px;
      z-index:1;
      display:flex;
      width:100%;
      padding-right: 7px;
      justify-content:flex-end;
      i{
        font-family:"Font Awesome 5 Free";
        cursor: pointer;
      }
      &--List{
        display: none;
        // display: flex;
        flex-direction: column;
        position:absolute;
        top:33px;
        z-index:1;
        width:100%;
        &:after{
          content: "";
          position: absolute;
          top: -16px;
          right: 2.5px;
          border-width: 8px;
          border-style: solid;
          border-color: transparent transparent #e4e4e4 transparent;
        }
        p{
          display: grid;
          grid-template-columns: 36px auto;
          grid-gap: 5px;
          border-bottom: 1px solid lightgray;
          color: #3e3e3e;
          cursor: pointer;
          align-items: center;
          font-family: Roboto;
          background: #f5f5f5;
          transition: 0.2s;
          &:hover{
            background: rgba(255,255,255,0.9);
          }
        }
        i{
          padding: 10px;
          font-size: 16px;
          background: white;
          color: #FF4149;
          font-family:"Font Awesome 5 Free";
        }
      }
      &--muted{
        display: none;
      }
      &--Mute.userMute{
        .UserMenuActions{
          &--notMute{
            display: none;
          }
          &--muted{
            display: block;
          }
        }
      }

    }
    &--ObserverChat{
      background: white!important;
    }
  }
</style>
<style lang="scss" scoped>
.Videochat{
  width: 100%;
  padding: 1px;
  height: 100%;
  &--View{
    border: 1px solid lightgray;
    height: 100%;
    border-radius: 6px;
    position: relative;
    display: flex;
    flex-direction: column;
  }
  &--Actions{
    display: flex;
    align-items: center;
    padding: 10px;
    justify-content: space-between;
    background: rgba(0, 0, 0, 0.5);
    color: white;
    font-size: 20px;
    box-shadow: 0 2px 5px rgb(0 0 0 / 50%);
    div{
      display: flex;
      gap: 20px;
      align-items: center;
    }
    i{
      cursor: pointer;
      &:hover{
        color: var(--primary-color);
      }
    }
    h1{
      font-size: 16px;
    }
    .CommunityButton {
      font-size: 12px;
    }
  }
  &--Content{
    display: grid;
    grid-template-columns: auto 350px;
    height: 100%;
    grid-gap: 5px;
    background: rgba(0,0,0,0.8);
    &.tabs-hidden{
      grid-template-columns: auto 0px;
    }
  }
  &--Video{
    width: 100%;
    display: flex;
    gap:10px;
    padding: 5px 0 5px 5px;
  }
  &--Cameras{
    width: 100%;
    display: flex;
    gap:10px;
    position: relative;
  }
  &--Tabs{
    height: 100%;
    position: relative;
  }
  &--CloseTabs{
    position: absolute;
    top: 14px;
    right: 7px;
    transition: 0.1s;
    cursor: pointer;
    &:hover{
      opacity: 0.5;
    }
    span:nth-child(1){
      transform: rotate(45deg) translate(-1px, -3px);
    }
    span:nth-child(2){
      transform: rotate(135deg) translate(-12px, 0px);
    }
    span{
      display: flex;
      width: 20px;
      height: 2px;
      margin-bottom: 5px;
      position: relative;
      background: var(--accent-color);
      border-radius: 3px;
      z-index: 1;
      transform-origin: 5px 0px;
    }
  }
  &--Screen{
    width: 100%;
    height: 100%;
  }
}
.Stream{
  &--min{
    grid-template-columns: auto auto;
  }
  &--few{
    grid-template-rows: auto auto;
    grid-template-columns: auto auto;
  }
  &--medium{
    grid-template-columns: auto auto auto;
    grid-template-rows: auto auto;
  }
  &--almostCompleted{
    grid-template-columns: auto auto auto auto;
    grid-template-rows: auto auto;
  }
  &--full{
    grid-template-columns: auto auto auto auto;
    grid-template-rows: auto auto auto;
  }
  &--infinite{
    grid-template-columns: auto auto auto auto auto;
    grid-template-rows: auto auto auto auto;
  }
}
@media screen and ( max-width: 768px ) {
  .Videochat{
    &--User{
      position: absolute;
      bottom: 0;
      right: 0;
      z-index: 1;
    }
    &--Tabs{
      position: absolute;
      width: 100%;
      z-index: 1;
    }
    &.screen-view{
      .Videochat--Cameras{
        display: none;
      }
      .Videochat--Video{
        grid-template-columns: auto;
      }
    }
  }
  .Stream{
    &--medium, &--almostCompleted, &--full, &--infinite {
      grid-template-columns: auto auto;
    }
    &--min{
      grid-template-columns: auto;
    }
  }
}
</style>
