const OT = require('@opentok/client');

let session;
let publisher;

function initSession(sessionId){
  session = OT.initSession(process.env.VUE_APP_TOKBOX_KEY, sessionId);
}

function connectSession(token, isObservator, isClient, nickname){
  session.connect(token, function (error) {
    if(session.capabilities.publish == 1 && !isObservator) {
      initPublisher(isClient, nickname)
      session.publish(publisher);
    }
  })
}

function initPublisher(isClient, nickname){
  const publisherOptions = {
    name: isClient ? "Moderador" : nickname,
    insertMode: 'append',
    data:{name:""},
    width:84,
    height:84,
    style: {buttonDisplayMode: 'off'}
  }
  publisher = OT.initPublisher('Videochat--User', publisherOptions)
}

function canShareScreen(){
  let canShare = false
  OT.checkScreenSharingCapability(function(response) {
    if(!response.supported || response.extensionRegistered === false) {
      canShare = false
    }
    canShare = true
  });

  return canShare
}

function createSignal(userId, id, user, action, message){
  let signalData = {userId:userId, id: id, type: action, user: user, message: message }
  session.signal({
    data:signalData
  });
}

function muteCurrentUser(publishAudio){
  publisher.publishAudio(publishAudio)
}

function getSession(setNull){
  if(setNull) session = null
  return session
}

function getPublisher(setNull){
  if(setNull) publisher = null
  return publisher
}

function getScreenPublisher(){
  return screenPublisher
}

async function applyBlurEffect(applyEffect){
  if(applyEffect){
    await publisher.applyVideoFilter({
      type: 'backgroundBlur',
      blurStrength: 'high',
    })
  }
  else publisher.clearVideoFilter()
}

export {
  initSession,
  connectSession,
  getSession,
  getPublisher,
  createSignal,
  muteCurrentUser,
  canShareScreen,
  applyBlurEffect
};
