<template lang="html">
  <div class="Actions" :class="{'WAT':isWatFocus}">
    <h1>{{title}}</h1>
    <div class="Actions--Wrapper" ref="MobileMenuActionsBar">
      <div class="Actions--Icons" v-show="isDesktopSize || openMenuMobile">
        <div class="Actions--Element" v-show="showFullSize" v-on:click="$emit('full-size')">
          <p>Pantalla completa</p>
          <i class="fas fa-expand smallIcon"></i>
        </div>
        <div class="Actions--Element" v-show="!isMuted && showMuteOption" v-on:click="$emit('mute')">
          <p>Desactivar micrófono</p>
          <i class="fas fa-microphone-alt smallIcon"></i>
        </div>
        <div class="Actions--Element" v-show="isMuted && showMuteOption" v-on:click="$emit('mute')">
          <p>Activar micrófono</p>
          <i class="fas fa-microphone-alt-slash smallIcon"></i>
        </div>
        <!--<div class="Actions--Element" v-on:click="$emit('applyBlurEffect')">
          <p v-show="!isBlurEffect">Difuminar fondo</p>
          <p v-show="isBlurEffect">Quitar fondo difuminado</p>
          <i :class="{'active':isBlurEffect}">
            <span class="material-symbols-outlined">background_replace</span>
          </i>
        </div>-->
        <div class="Actions--Element" v-show="showScreenShare && isDesktopSize" v-on:click="$emit('share-screen')">
          <p v-show="!isSharingScreen">Compartir pantalla</p>
          <p v-show="isSharingScreen">Dejar de compartir</p>
          <i class="fas fa-desktop smallIcon" v-show="showScreenShare" :class="{'active':isSharingScreen}"></i>
        </div>
        <div class="Actions--Element" v-show="showUrls" v-on:click="$emit('share-links')">
          <p>Url-s de acceso</p>
          <i class="fas fa-share-alt smallIcon"></i>
        </div>
        <div class="Actions--Element" v-show="showPermissions" v-on:click="$emit('open-permissions',2)">
          <span v-show="badgePermissions > 0">{{badgePermissions}}</span>
          <p>Gestión de usuarios</p>
          <i class="fas fa-users smallIcon" :class="{'active':activeTab == 2}"></i>
        </div>
        <div class="Actions--Element" v-show="showComments" v-on:click="$emit('change-chat',3)">
          <p>Anotaciones</p>
          <i class="far fa-clipboard" :class="{'active':activeTab == 3}"></i>
        </div>
        <div class="Actions--Element" v-on:click="$emit('change-chat',0)">
          <span v-show="badgeChatActivity > 0">{{badgeChatActivity}}</span>
          <p>Chat actividad</p>
          <i class="far fa-comment-dots" :class="{'active':activeTab == 0}"></i>
        </div>
        <div class="Actions--Element Actions--ChatObserver" v-show="showObserverChat" v-on:click="$emit('change-chat',1)">
          <p>Chat observador</p>
          <span class="Actions--badge" v-show="badgeChatObserver > 0">{{badgeChatObserver}}</span>
          <i class="fas fa-user-secret" :class="{'active':activeTab == 1}"></i>
          <i class="far fa-comment" :class="{'active':activeTab == 1}"></i>
        </div>
      </div>
      <FocusButton color="red" v-on:click="$emit('exit')">{{$t('videochat_exit')}}</FocusButton>
      <i class="fa fa-ellipsis-v Actions--MobileDots" v-show="!isDesktopSize" v-on:click="showMenuMobile()"></i>
    </div>
  </div>
</template>
<script>
import { onClickOutside } from '@vueuse/core'
import { ref } from 'vue'
import { mapGetters } from 'vuex';
import FocusButton from './FocusButton.vue'
export default {
  name: 'ActionsBar',
  components:{
    FocusButton
  },
  props:{
    isMuted:{
      type: Boolean,
      default: false
    },
    isSharingScreen:{
      type: Boolean,
      default: false
    },
    isBlurEffect:{
      type: Boolean,
      default: false
    },
    showObserverChat:{
      type: Boolean,
      default: false
    },
    showPermissions:{
      type: Boolean,
      default: false
    },
    showScreenShare:{
      type: Boolean,
      default: false
    },
    showFullSize:{
      type: Boolean,
      default: false
    },
    showMuteOption:{
      type: Boolean,
      default: false
    },
    showUrls:{
      type: Boolean,
      default: false
    },
    showComments:{
      type: Boolean,
      default: false
    },
    activeTab:{
      type: Number,
      default: -1
    },
    badgePermissions:{
      type: Number,
      default:0
    },
    badgeChatObserver:{
      type: Number,
      default:0
    },
    badgeChatActivity:{
      type: Number,
      default: 0
    },
    title:{
      type: String,
      default:''
    },
    isWatFocus:{
      type: Boolean,
      default: false
    }
  },
  data(){
    return{
      isDesktopSize: true
    }
  },
  methods:{
    resize(){
      if(window.innerWidth <= 768) this.isDesktopSize = false
      else this.isDesktopSize = true
    }
  },
  created(){
    window.addEventListener('resize', this.resize);
    this.resize()
  },
  setup() {
    const MobileMenuActionsBar = ref(null)
    const openMenuMobile = ref(false)
    onClickOutside(MobileMenuActionsBar, (event) => openMenuMobile.value = false)

    const showMenuMobile = function(){
      openMenuMobile.value = !openMenuMobile.value
    }
    return { MobileMenuActionsBar, openMenuMobile, showMenuMobile }
  }
}
</script>
<style lang="scss" scoped>
.Actions{
  display: flex;
  align-items: center;
  padding: 10px;
  justify-content: space-between;
  background: rgba(0, 0, 0, 0.5);
  color: white;
  font-size: 20px;
  box-shadow: 0 2px 5px rgb(0 0 0 / 50%);
  &.WAT{
    background: url(/img/waves-bg-2.adea1a7b.png), linear-gradient(70.59deg, #361466 5.34%, #895AD2 96.14%);
  }
  &--Wrapper{
    display: flex;
    gap: 20px;
    align-items: center;
  }
  &--Icons{
    display: flex;
    gap: 20px;
  }
  &--Element{
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover p{
      visibility: visible;
      opacity: 1;
    }
    &:hover i, i.active{
      color: var(--primary-color);
    }
    i.active.fa-desktop::after, i.active .material-symbols-outlined::after{
      content: "";
      padding: 1px;
      width: 30px;
      height: 1px;
      position: absolute;
      background: var(--primary-color);
      transform: rotate(45deg);
      left: -5px;
      top: 10px;
    }
    i.active .material-symbols-outlined::after{
      left: 0px;
      top: 13px;
    }
    p{
      font-family: Roboto;
      letter-spacing: 0.5px;
      font-size: 12px;
      position: absolute;
      margin: 0;
      visibility: hidden;
      text-align: center;
      border-radius: 6px;
      padding: 5px 10px;
      top: calc(100% + 10px);
      left: 50%;
      margin-left: -65px;
      height: fit-content;
      width: 130px;
      z-index: 1;
      opacity: 0;
      transition: opacity 1s;
      color: black;
      background: #fff;
      transition: opacity 0.15s ease-in, top 0.15s ease-in, width 0.15s ease-in;
      box-shadow: 0 0px 3px black;
      border: 1px solid var(--accent-color);
      z-index: 2;
      &:after{
        content: "";
        position: absolute;
        bottom: 100%;
        left: 50%;
        margin-left: -5px;
        border-width: 5px;
        border-style: solid;
        border-color: transparent transparent white transparent;
      }
    }
  }
  &--ChatObserver{
    i{
      font-size: 16px;
    }
  }
  &--MobileDots{
    display: none;
  }
  i{
    cursor: pointer;
    display: flex;
    &.fa-comment{
      padding-bottom: 10px;
    }
    span{
      font-size: 30px;
    }
  }
  h1{
    font-size: 16px;
    font-family: Roboto condensed;
  }
  .FocusButton {
      font-size: 12px;
      &:hover{
        opacity: 0.6;
      }
  }
  .smallIcon{
    font-size: 18px;
  }
  &--badge{
    position: absolute;
    background: red;
    border: 0.5px solid #FFFFFF;
    color: white;
    border-radius: 50%;
    width: 15px;
    height: 15px;
    font-size: 11px;
    display: flex;
    align-items: center;
    justify-content: center;
    top: -5px;
    right: -5px;
  }
  @media screen and ( max-width: 768px ) {
    &--MobileDots{
      display: block;
    }
    &--Icons{
      position: absolute;
      flex-direction: column;
      background: white;
      right: 0;
      top: 46px;
      gap: 0;
      z-index: 2;
      box-shadow: 0 4px 10px lightgrey;
    }
    &--Element{
      justify-content: flex-start;
      padding: 0 5px;
      display: grid;
      grid-template-columns: 30px auto;
      padding:2.5px 5px;
      cursor: pointer;
      transition: 0.2s;
      text-align: center;
      &:hover{
        background: lightgray;
      }
      i{
        order:1;
        color: var(--primary-color);
      }
      .fa-comment{
        display: none;
      }
      p{
        visibility: visible;
        position: relative;
        opacity: 1;
        border: none;
        box-shadow: none;
        width: fit-content;
        left: initial;
        margin-left: 0;
        order:2;
        background: transparent;
        top: 0;
        &:after{
          display: none;
        }
      }
    }
  }
}

</style>
